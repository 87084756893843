<template>
  <div class="map-popup" :class="{'show':selectedObject,'full':fullView}">
    <object-card
      v-if="selectedObject"
      class="object-card"
      :img="selectedObject.images[Object.keys(selectedObject.images)[0]]"
      :width="fullView?'884px':'488px'"
      :height="fullView?'442px':'366px'"
    />
    <div class="object-preview" v-if="selectedObject">
      <div class="info">
        <div class="title">{{selectedObject.title}}</div>
        <scrolled-content class="description">
          <tag :title="selectedObject.type.name" />
          <div class="text" v-html="selectedObject.description"></div>
          <!-- <txt class="text" :content="selectedObject.description" /> -->
          <object-item-adds class="adds" :adds="selectedObject.additions" />
        </scrolled-content>
      </div>
    </div>

    <content-wrapper class="object-summary">
      <object-summary />
      <near-objects title="Рядом находятся" />
    </content-wrapper>

    <div class="bottom-bar">
      <div class="button-back" @click="closePopup()">
        <div class="icon">
          <close-icon />
        </div>
        <div class="text">
          <span>Закрыть</span>
        </div>
      </div>
      <div class="button-more" @click="fullPopup()">
        <div class="text">
          <span>Подробнее</span>
        </div>
        <div class="icon">
          <more-icon />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import ContentWrapper from "@/components/Wrappers/ContentWrapper";
import ObjectSummary from "@/components/ObjectSummary";
import NearObjects from "@/components/NearObjects";
import CloseIcon from "@/components/Icons/Close";
import MoreIcon from "@/components/Icons/ArrowMore";
import ObjectCard from "@/components/Parts/ObjectCard";
import ObjectItemAdds from "@/components/Parts/ObjectItemAdds";
import ScrolledContent from "@/components/Wrappers/ScrolledContent";
import Tag from "@/components/Parts/TagSmall";

export default {
  components: {
    ContentWrapper,
    ObjectSummary,
    NearObjects,
    CloseIcon,
    MoreIcon,
    ObjectCard,
    ObjectItemAdds,
    Tag,
    ScrolledContent
  },
  data() {
    return {
      showShade: false,
      fullView: false
    };
  },
  computed: {
    ...mapGetters(["selectedObject", "nearObjects"])
  },
  watch: {
    selectedObject(val) {
      if (!val) this.fullView = false;
    }
  },
  methods: {
    closePopup() {
      this.$store.dispatch("setSelectedObject", null);
    },
    fullPopup() {
      this.fullView = true;
      // this.$router.push({
      //   path: `/object/${this.selectedObject.id}`,
      //   query: this.$route.query
      // });
    }
  }
};
</script>


<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";

.map-popup {
  background: $color_grey_2;
  width: 100%;
  height: 0;
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: 100;
  // transition: all 0.3s ease-in;
  margin-bottom: 0;
  overflow: hidden;
  transition-duration: 0;

  &.show {
    height: 640px;
    transition: all 0.3s ease-in;
    display: block;
  }

  & > .object-card {
    position: relative;
    opacity: 1;
    // z-index: 1;
    top: 40px;
    left: 37px;
  }

  .object-summary {
    position: relative;
    opacity: 0;
    z-index: -1;
    /deep/ .photos {
      opacity: 0;
    }
  }

  .object-preview {
    .info {
      position: absolute;
      top: 40px;
      left: 556px;
      width: 512px;
      height: 432px;
      display: flex;
      flex-direction: column;

      .tag {
        margin-bottom: 32px;
      }

      .title {
        font-size: 44px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: -0.75px;
        color: rgba(#fff, 0.97);
        margin-bottom: 40px;
      }

      .description {
        position: relative;
        overflow: hidden;
        width: 100%;

        .text {
          width: 488px;

          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.5;
          letter-spacing: -0.1px;
          color: rgba(#fff, 0.8);
          position: relative;
          padding-bottom: 32px;
          margin-bottom: 32px;
          border-bottom: 4px solid $color_grey_3;
        }

        .adds {
          margin-bottom: 32px;
        }
      }
    }
  }

  .bottom-bar {
    position: absolute;
    left: 0;
    right: 0;
    top: auto;
    bottom: 0;
    width: 100%;
    height: 168px;
    z-index: 1000;
    background: $color_grey_2;
    -webkit-transition: 0.3s ease-out;
    transition: 0.3s ease-out;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 4px;
      background: rgba(#fff, 0.16);
    }

    &::before {
      position: absolute;
      content: "";
      width: 100%;
      height: 168px;
      bottom: 56px;
      z-index: -1;
      // z-index: 100;
      background-image: linear-gradient(
        to bottom,
        rgba($color_grey_2, 0),
        $color_grey_2 50%
      );
      // background: red;
    }

    .button-back {
      position: absolute;
      left: 32px;
      top: 28px;
      width: 338px;
      height: 112px;
      border-radius: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grad_grey;
      opacity: 1;

      .icon {
        width: 64px;
        height: 64px;
        margin-right: 16px;
      }

      .text {
        font-size: 44px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: -0.5px;
      }
    }

    .button-more {
      position: absolute;
      right: 32px;
      top: 28px;
      width: 429px;
      height: 112px;
      border-radius: 55px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: $grad_yellow;
      opacity: 1;

      .icon {
        width: 64px;
        height: 64px;
        background: rgba(31, 40, 65, 0.1);
        border-radius: 50%;
        margin-left: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .text {
        font-size: 44px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.09;
        letter-spacing: -0.5px;
        text-shadow: 0 1px 2px rgba(#000, 0.1);
      }
    }
  }

  &.full {
    height: 1192px;
    bottom: 168px;
    transition: all 0.3s ease-in;

    .object-card {
      transition: all 0.3s ease-in 0ms, opacity 0s linear 1.3s;
      position: absolute;
      top: 56px;
      left: 32px;
      opacity: 0;
      z-index: -1;
      /deep/ .image {
        border-radius: 0;
      }
    }
    .bottom-bar,
    & > .object-preview {
      display: none;
    }
    .object-summary {
      transition: all 0.2s ease-in;
      opacity: 1;
    }

    /deep/ .photos {
      opacity: 1;
      transition: opacity 1s ease-in;
      transition-delay: 0.3s;
    }
  }
}
</style>